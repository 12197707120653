import * as React from "react";
import { cn, DataObserveKey, FocusableComponent, VisualComponent } from "@siteimprove/fancylib";
import * as scss from "./scale.scss";

type CommonScaleProps = {
	colorRange?: "cool" | "warm";
	/** Same color for all levels depending on the value */
	monochrome?: boolean;
	reverseColorRange?: boolean;
	shape?: "circle" | "soft" | "square";
	"aria-label"?: string;
} & DataObserveKey &
	FocusableComponent &
	VisualComponent;

export type Scale4Props = {
	value: 1 | 2 | 3 | 4;
	length?: 4;
};

export type Scale3Props = {
	value: 1 | 2 | 3;
	length: 3;
};

export type ScaleProps = (Scale4Props | Scale3Props) & CommonScaleProps;

const ranges = {
	3: {
		warm: [scss.warm1, scss.warm2, scss.warm4],
		cool: [scss.cool1, scss.cool2, scss.cool4],
	},
	4: {
		warm: [scss.warm1, scss.warm2, scss.warm3, scss.warm4],
		cool: [scss.cool1, scss.cool2, scss.cool3, scss.cool4],
	},
};

export function Scale(props: ScaleProps): JSX.Element {
	const {
		value,
		length = 4,
		colorRange = "cool",
		monochrome,
		shape = "circle",
		reverseColorRange,
		...rest
	} = props;

	let range = ranges[length]![colorRange];
	range = reverseColorRange ? [...range].reverse() : range;
	range = range.slice(0, value);

	if (monochrome) {
		range = range.map(() => range[range.length - 1]);
	}

	return (
		<div
			{...rest}
			data-component="scale"
			role="img"
			aria-label={props["aria-label"] || `${value}/${length}`}
			className={scss.scale}
		>
			{Array.from({ length }, (_, i) => (
				<span key={i} className={cn(range[i], scss[shape])} />
			))}
		</div>
	);
}
