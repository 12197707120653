import React from "react";
import { Link as GatsbyLink } from "gatsby";
import { Paragraph, TextContainer, Ul } from "@siteimprove/fancylib";
import {
	Knobs,
	Example,
	DocPageMeta,
	InlineMessage,
	ContentSection,
	HeaderSection,
	ImportExample,
	Header,
	Code,
} from "../../../../../src/docs";
import { LabWarning } from "../../../../../src/docs/docs-lab-warning";
import { Scale } from "./scale";

export const Meta: DocPageMeta = {
	category: "Data Visualization",
	title: "Scale",
	notepad: "https://hackmd.io/3JFqaSkkRc650shcrPrQdQ",
};

export default (): JSX.Element => (
	<>
		<HeaderSection
			title="Scale"
			subTitle="Visually communicate the status or progress of an item using a clear, linear 3- or 4-step scale."
		/>
		<ContentSection>
			<TextContainer article>
				<LabWarning />
				<ImportExample lab component={Scale} />
				<Header.H2>Examples</Header.H2>
				<Paragraph>
					The Scale component visually communicates the relative status or progress of an item using
					a linear sequence of steps. Use it to quickly convey information where precise values are
					not needed.
				</Paragraph>
				<Header.H3>Variations</Header.H3>
				<Paragraph>
					The component can appear with either 3 or 4 steps. The default is 4 steps. The variant is
					controlled by the optional <Code>length</Code> parameter.
				</Paragraph>
				<Header.H3>Basic Usage</Header.H3>
				<Paragraph>
					The Scale component helps users quickly assess a value within a defined range (e.g., task
					difficulties, severity, priority).
				</Paragraph>
				<Example fn={BasicUsage} />
				<Header.H3>Warm Color Range</Header.H3>
				<Paragraph>
					Use a progression of warm colors (i.e., green to orange to red) to convey increasing
					intensity or urgency.
				</Paragraph>
				<Example fn={WarmColorRange} />
				<Header.H3>Reverse Color Range</Header.H3>
				<Paragraph>
					Reverse the cool or warm color range (e.g., red to orange to green) for situations where
					higher values are more negative.
				</Paragraph>
				<Example fn={ReverseColorRange} />
				<Header.H3>Monochrome</Header.H3>
				<Paragraph>
					Use a single color derived from the{" "}
					<a href="https://fancy.siteimprove.com/design-tokens/#heatrange:-warm">
						Heatrange warm/cold
					</a>{" "}
					design tokens, with varying saturation and value to visually indicate progression.
				</Paragraph>
				<Example fn={MonochromeWarm} />
				<Header.H3>Monochrome Reverse</Header.H3>
				<Paragraph>Reverse the monochrome progression for alternative scenarios.</Paragraph>
				<Example fn={MonochromeCoolReverse} />
				<Header.H3>Shape Options</Header.H3>{" "}
				<Ul
					items={[
						<>
							<b>Circles</b>: Familiar and friendly, best for discrete steps and conveying a softer
							aesthetic.
						</>,
						<>
							<b>Round square</b>: A balanced choice combining the approachability of circles with a
							more structured feel.
						</>,
						<>
							<b>Sharp square</b>: Project a technical and precise look, ideal for data-driven
							contexts and formal interfaces.
						</>,
					]}
				/>
				<Example fn={ShapeOptions} />
				<Header.H2>3-step variant</Header.H2>
				<Ul
					items={[
						<>All customization options for the 4-step scale apply to the 3-step variant.</>,
						<>Consider this variant when a more simplified representation is suitable.</>,
					]}
				/>
				<Example fn={ThreeStepVariant} />
				<Header.H2>Properties</Header.H2>
				<Knobs
					component={Scale}
					initialProps={() => ({
						value: 1 as const,
					})}
				/>
				<TextContainer article>
					<Header.H2>Guidelines</Header.H2>
					<Header.H3>Best practices</Header.H3>
					<InlineMessage variant="best-practices">
						<Header.H4>General</Header.H4>
						<Paragraph>
							Use <Code>Scale</Code> when
						</Paragraph>
						<Ul
							items={[
								"Visually communicate the status or progress of an item to the user.",
								<>
									You want to quickly communicate a relative value (e.g., "easy," "medium,"
									"difficult").
								</>,
							]}
						/>
						<Header.H4>Placement</Header.H4>
						<Paragraph>
							<Code>Scale</Code> is typically used in the following places:
						</Paragraph>
						<Ul
							items={[
								<>
									{" "}
									<GatsbyLink to="/lab/components/Tables and lists/Table">
										Table
									</GatsbyLink> and{" "}
									<GatsbyLink to="/lab/components/Tables and lists/List table">
										List table
									</GatsbyLink>
									: To display the status of individual items.
								</>,
								<>
									<GatsbyLink to="/lab/components/Text/Highlight">Highlight</GatsbyLink>: To provide
									an overview of progress or performance.
								</>,
								<>
									Task Difficulty: Indicate how easy or difficult a task is (e.g., "Easy," "Medium,"
									"Hard").
								</>,
								<>Product Ratings: Display user-generated ratings or reviews.</>,
								<>Skill Level: Represent the proficiency level required for a task or activity.</>,
							]}
						/>
						<Header.H4>Style</Header.H4>
						<Ul
							items={[
								<>
									<b>Siteimprove Design System</b>: Adhere to Siteimprove's guidelines for color,
									typography, and spacing. If you are not using a component from Fancy, match the
									styling of your <Code>Scale</Code> to existing components for visual consistency.
								</>,
								<>Steps should be arranged in a single, horizontal or vertical line.</>,
								<>A minimum of 3 steps, maximum of 4, to maintain clarity.</>,
								<>
									Each step should be visually distinct from the others (color, fill, icon, etc.)
								</>,
								<>
									Apply the Scale component consistently throughout your interface for similar types
									of data or assessments.
								</>,
								<>
									Use the Scale only when relative value is the primary communication goal. Avoid it
									for precise measurements.
								</>,
								<>Ensure the scale's direction is consistent (left-to-right).</>,
								<>Don't assume all users will interpret the scale in the same way.</>,
							]}
						/>
					</InlineMessage>
					<Header.H3>Do not use when</Header.H3>
					<InlineMessage variant="do-not-use-when">
						<Ul
							items={[
								"The data is not linear or has complex relationships.",
								"Precise numerical values are required (use a numerical input instead).",
								"You have more than four distinct categories to display.",
							]}
						/>
					</InlineMessage>
					<Header.H3>Accessibility</Header.H3>
					<InlineMessage variant="accessibility">
						<Header.H4>For designers</Header.H4>
						<Ul
							items={[
								"Ensure sufficient color contrast and consider alternative indicators (patterns, icons) for colorblind users.",
								"Don't rely solely on color to convey meaning.",
							]}
						/>
						<Header.H4>For developers</Header.H4>
						<Paragraph>
							This component comes with built-in accessibility, no extra work required.
						</Paragraph>
					</InlineMessage>
					<Paragraph>
						Explore detailed guidelines for this component:{" "}
						<a href="https://siteimprove-wgs.atlassian.net/wiki/x/4wQNeQ">
							Accessibility Specifications
						</a>
					</Paragraph>
					<Header.H3>Writing</Header.H3>
					<InlineMessage variant="writing">
						<Ul
							items={[
								"Keep labels short, clear, and consistent.",
								"Ensure the context around the Scale component explains its purpose and meaning.",
							]}
						/>
					</InlineMessage>
				</TextContainer>
			</TextContainer>
		</ContentSection>
	</>
);

const BasicUsage = () => (
	<>
		<Scale value={1} />
		<Scale value={2} />
		<Scale value={3} />
		<Scale value={4} />
	</>
);

const WarmColorRange = () => (
	<>
		<Scale colorRange="warm" value={1} />
		<Scale colorRange="warm" value={2} />
		<Scale colorRange="warm" value={3} />
		<Scale colorRange="warm" value={4} />
	</>
);

const ReverseColorRange = () => (
	<>
		<Scale reverseColorRange value={1} />
		<Scale reverseColorRange value={2} />
		<Scale reverseColorRange value={3} />
		<Scale reverseColorRange value={4} />
	</>
);

const MonochromeWarm = () => (
	<>
		<Scale colorRange="warm" monochrome value={1} />
		<Scale colorRange="warm" monochrome value={2} />
		<Scale colorRange="warm" monochrome value={3} />
		<Scale colorRange="warm" monochrome value={4} />
	</>
);

const MonochromeCoolReverse = () => (
	<>
		<Scale monochrome reverseColorRange value={1} />
		<Scale monochrome reverseColorRange value={2} />
		<Scale monochrome reverseColorRange value={3} />
		<Scale monochrome reverseColorRange value={4} />
	</>
);

const ShapeOptions = () => (
	<>
		<Scale shape="circle" value={2} />
		<Scale shape="soft" value={2} />
		<Scale shape="square" value={2} />
	</>
);

const ThreeStepVariant = () => (
	<>
		<Scale length={3} value={1} />
		<Scale length={3} value={2} />
		<Scale length={3} value={3} />
		<Scale length={3} value={1} colorRange="warm" />
		<Scale length={3} value={2} colorRange="warm" />
		<Scale length={3} value={3} colorRange="warm" />
	</>
);
